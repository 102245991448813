import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Responsible from '../components/ResponsibleGaming/ResponsibleGaming'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { RESPONSIBLE_GAMING as RESPONSIBLE_GAMING_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Responsible Gaming',
    url: RESPONSIBLE_GAMING_LINK,
  },
]

const ResponsibleGaming: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Resposible Gaming: Read How to Play Real Money Gaming Responsibly"
        description="Read how to play responsibly on Mega. Mega is an entertainment platform and encourages all its users to play, learn, earn, and have fun. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Responsible />
    </Layout>
  )
}

export default ResponsibleGaming
